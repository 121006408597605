































































































.header {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  height: 3.9375rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 1010;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  @media (max-width: 768px) {
    padding: 0 15px;
  }

  .left-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    a {
      text-decoration: none;
    }
    .logo-section {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      .logo-img {
        @media (max-width: 575px) {
          margin-right: 8px;
        }
        img {
          height: 27px;
          width: auto;
          object-fit: scale-down;
        }
      }
      .text-logo {
        margin-left: 5px;
        span {
          &.effect {
            background: #ff5c00;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: clamp(14px, 1.5vw, 18px);
            text-transform: uppercase;
            font-weight: 900;
            line-height: 120%;
          }
          &.eggsbook {
            font-size: clamp(14px, 1.5vw, 18px);
            text-transform: uppercase;
            font-weight: 900;
            color: #5d5d5d;
            line-height: 120%;
          }
        }
      }
    }
    .menu-icon {
      margin-right: 2.25rem;
      height: 3rem;
      width: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 575px) {
        margin-right: 1rem;
        height: 1.25rem;
        width: 1.25rem;
      }
      @media (max-width: 374px) {
        margin-right: 7px;
      }
      button {
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        background: transparent;
        color: #6c757d;
        border: 0;
        svg {
          width: 1.25rem;
        }
      }
    }
  }
  .right-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 180px;
    width: 100%;
    .avatar-fr {
      justify-content: space-around;
    }
    .profile-section {
      width: 100%;
    }
    .item-header {
      width: 100%;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      button {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: none !important;
      }
    }
    .avatar-fr {
      max-width: 180px;
      width: 100%;
      background: #f0f0f0;
      padding: 8px 0;
      border-radius: 115px;
      .name {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #555555;
      }
      .avatar {
        color: #6c757d;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.1rem;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
