// Your variable overrides
$body-bg: #fff;
$body-color: #111;

@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap-vue/src/index.scss";
@import url("./font.scss");

html,
body {
    transition: all ease-in 0.1s;
    font-family: "Epilogue", sans-serif;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    font-weight: 500;
    font-style: normal;
}

html {
    scrollbar-width: auto;
    scrollbar-color: #d6d6d6;

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
        width: 9px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(63, 39, 75, 0.801);
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d6d6d6;
        border-radius: 10px;
        border: 1px solid #ffffff;
    }
}

@media (min-width: 1024px) {
    .xxl-3 {
        flex: 0 0 500px;
        max-width: 500px;
    }

    .xxl-9 {
        flex: 1 1 auto;
        max-width: calc(100% - 500px);
    }
}

.dropdown-item.active,
.dropdown-item:active {
    background: #FF5C00;
}

.dropdown-item {

    i,
    svg {
        color: #a8a8b1;
        margin-right: 5px;
    }
}

.btn-egg {
    border: 1px solid #ccc;
    box-shadow: 0 2px 6px 0 #ccc;
    letter-spacing: 0.05rem;
    position: relative;
    background: #FF5C00;
    color: #fff !important;
    min-width: 130px;
    width: auto;
    min-width: 150px;
    max-width: 225px;
    height: auto;
    min-height: 35px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    border-radius: 0.3rem;
    z-index: 1;

    &:hover,
    &:focus,
    &:focus-visible {
        box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.2);
    }
}

.cursor-pointer {
    cursor: pointer;
}

:root {
    --animate-duration: 0.9s;
    --animate-delay: 0.2s;
    --animate-repeat: 1;
}

@media (min-width: 2000px) {

    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1440px;
    }
}

@media (min-width: 2560px) {

    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1600px;
    }
}

@media (min-width: 3000px) {

    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 2100px;
    }
}

.toast-success {
    background-color: #FF5C00 !important;
    border-radius: 5px !important;
    opacity: 1 !important;

    .toast-message {
        font-weight: 600 !important;
        font-family: "Nunito", "Titillium Web", sans-serif !important;
    }
}

.toast-error {
    background-color: #b91c30 !important;
    border-radius: 5px !important;
    opacity: 1 !important;

    .toast-message {
        font-weight: 600 !important;
        font-family: "Nunito", "Titillium Web", sans-serif !important;
    }
}