


















.footer {
  background-image: url(~@/assets/images/background/overlay.png);
  background-size: cover;
  background-position: center center;
  min-height: 105px;
  -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
  box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  position: relative;
  width: 100%;
  right: 0;
  left: 0;
  .footer-content {
    max-width: 1500px;
    color: rgba(255, 255, 255, 1);
    // background: #fff;
    border-radius: 10px;
    // box-shadow: 0px 0px 10px 2px #00000038;
    font-weight: 500;
    height: 100%;
    text-align: justify;
    padding: 30px 5px;
    span {
      font-weight: 700;
    }
  }
}
