













































































































































.content-enter-active,
.content-leave-active {
  transition: width 10s ease-in-out;
}
@media (min-width: 1201px) {
  .activeMenu {
    max-width: calc(100% - 270px);
  }
}
.page-container {
  background-color: #f2f5f7;
  .page-wrapper-info {
    background-image: url(~@/assets/images/background/top-bg-login.png);
    background-size: cover;
    background-position: center center;
    min-height: 275px;
    padding: 4rem 80px 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .info-user {
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      .avatar {
        padding: 0.25rem;
        // background-color: #a8a8b1;
        border: 1px solid #ff5c00;
        max-width: 100%;
        height: auto;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        padding: 6px;
        img {
          // background: white;
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        padding-left: 20px;
        .name {
          color: #fff;
          font-weight: 500;
          font-size: clamp(15px, 1.25vw, 18px);
        }
        .action-user {
          margin-top: 7px;
          button {
            border-radius: 50%;
            border: 0;
            background: #ff5c00;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            padding: 5px;
            color: #fff;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 9px 12px rgba(0, 0, 0, 0.2);
            i,
            svg {
              width: 100%;
            }
          }
        }
      }
    }
    .page-title {
      margin-left: 15%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      min-height: 50px;
      @media (max-width: 991px) {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px;
      }
      @media (max-width: 576px) {
        margin-left: 0;
      }
      div {
        color: #fff;
        i,
        svg {
          font-size: 32px;
          margin-right: 25px;
        }
        span {
          font-size: 32px;
          font-weight: 700;
        }
      }
      .content-IB {
        font-weight: bold;
        margin-left: 10px;
        @media (max-width: 576px) {
          text-align: center;
        }
      }
    }
    @media (max-width: 1440px) {
      padding: 4.2rem 80px 40px;
      min-height: 230px;
    }
    @media (max-width: 576px) {
      min-height: 180px;
      padding: 4.2rem 20px 40px;
      .info-user {
        display: none;
      }
      .page-title {
        align-items: center;
      }
    }
  }
  .page-wrapper {
    width: 100%;
    position: relative;
    min-height: calc(100vh - 70px);
    margin-top: -70px;
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .page-content {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex: 1 1 auto;
      width: 100%;
      min-height: calc(100vh - 70px);
      position: relative;
      padding: 0 10px 0px;
    }
    @media (max-width: 1440px) {
      padding: 0 40px;
      margin-top: -30px;
    }
    @media (max-width: 1200px) {
      padding: 0 20px;
    }
    @media (max-width: 767px) {
      padding: 0;
      margin-top: -30px;
      .page-content {
        padding: 0 0 60px;
      }
    }
  }
}
